<template>
  <div id="history">
    <div class="nav">
      <router-link to="/historybrowse">浏览历史</router-link>
      <router-link to="/historydown">下载历史</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>

<style lang="scss" scoped>
#history {
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 40px;
  padding-top: 20px;
  .nav {
    height: 48px;
    border-bottom: 1px solid #e6e6e6;
    a {
      width: 125px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      color: #999999;
      font-size: 18px;
      &.router-link-exact-active::after {
        content: "";
        display: block;
        width: 125px;
        height: 8px;
        background-image: url("~@/assets/imgs/xz.png");
        background-size: 100% 100%;
      }
    }
    &::v-deep .router-link-exact-active {
      color: #00A4FF;
    }
  }
}
</style>

